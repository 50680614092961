//* Product Routes
export const ZEN_IT_HOME = '/product/zenadmin-platform'
export const ZENIT_SAAS_MANAGEMENT = '/product/app-management'

//* zencore links
export const WORKFLOW_AUTOMATION = '/zencore/workflow-automation'
export const EMPLOYEE_DIRECTORY = '/zencore/employee-directory'
export const DOCUMENT_MANGEMENT = '/zencore/document-management'
export const POLICIES_AND_PERMISSIONS = '/zencore/policies-and-permissions'
export const REPORTS_AND_ANALYTICS = '/zencore/reports-and-analytics'

//* zenhr links
export const ZEN_HR = '/zenhr'
export const TIME_AND_ATTENDANCE = '/zenhr/time-and-attendance'
export const PERFORMANCE_MANAGEMENT = '/zenhr/performance-management'

//* zenhr links
export const ZEN_EX = '/zenex'

//* zenit links
export const ZEN_IT = '/zenit'
export const IT_HELPDESK = '/zenit/it-helpdesk'
export const SAAS_MANAGEMENT = '/zenit/saas-management'
export const MOBILE_DEVICE_MANAGEMENT = '/zenit/mobile-device-management'
export const IDENTITY_MANAGEMENT = '/zenit/identity-management'
export const INTEGRATIONS = '/zenit/integrations'
export const INVENTORY_MANAGEMENT = '/zenit/inventory-management'

//* Solutions Routes
export const FOR_FOUNDERS_HR_AND_IT_ADMINS = '/solutions/for-founders-hr-and-it-admins'
export const BECOME_SECURITY_COMPLIANT = '/solutions/become-security-compliant'
export const SAVE_COSTS_AND_ESTABLISH_SCALABLE_PROCESSES =
  '/solutions/save-costs-establish-scalable-processes'

//* Company Routes
export const ABOUT_US = '/company/about-us'
export const CAREERS = 'https://wellfound.com/company/zenadminai'
export const WHY_ZENADMIN = '/why-zenadmin'
export const BLOG = '/blogs'
export const PARTNERS = '/partnership'

// policy link
export const PRIVACY_POLICY = '/privacy-policy'
export const TERMS_OF_SERVICE = '/terms-of-service'

export const HYBRID_APP = 'https://console.zenadmin.ai'
export const ZENEX_APP = 'https://user.zenadmin.ai/'
export const DEMO_LINK = 'https://meetings-eu1.hubspot.com/meetings/sharva'
export const LINKEDIN_LINK = 'https://www.linkedin.com/company/zenadmin-ai/'
export const X_LINK = 'https://x.com/zap_ai'
