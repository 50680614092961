export const BLOGS = '/blogs'

//* Product Routes
export const ZAP_PLATFORM = '/product/zenadmin-platform'
export const EMPLOYEE_LIFECYCLE_MANAGEMENT = '/product/employee-lifecycle-management'
export const PRODUCT_APP_MANAGEMENT = '/product/app-management'
export const PRODUCT_FREE_TRIAL_ZENADMIN = '/product/free-trial-zenadmin'

export const IDENTITY_MANAGEMENT = '/product/identity-management'

export const PRODUCT_IT_HELPDESK = '/product/it-helpdesk'
export const PRODUCT_IT_HELPDESK_SUPPORT = '/product/it-helpdesk-support'
export const PRODUCT_IT_SUPPORT_AND_MANAGEMENT = '/product/it-support-and-management'
export const PRODUCT_ITAM = '/product/itam'

export const INVENTORY_MANAGEMENT = '/product/inventory-management'
export const PRODUCT_MDM = '/product/mobile-device-management'
export const PEOPLE_MANAGEMENT = '/product/people-management'
export const DEVICE_LIFECYCLE_MANAGEMENT = '/product/device-lifecycle-management'
export const IT_ASSET_MANAGEMENT = '/product/it-asset-management'

//* Free Trial
export const IT_ASSET_MANAGEMENT_FREE_TRIAL = '/free-trial/it-asset-management'
export const IT_SUPPORT_FREE_TRIAL = '/free-trial/it-support'

//* LP
export const DECREASE_IT_BUDGET = '/lp/decrease-your-it-budget'
export const REDUCE_ITAM_COSTS = '/lp/reduce-your-itam-costs'
export const REDUCE_ITM_BUDGET = '/lp/reduce-your-itm-budget'

//* Solutions Routes
export const FOR_FOUNDERS_HR_AND_IT_ADMINS = '/solutions/for-founders-hr-and-it-admins'
export const BECOME_SECURITY_COMPLIANT = '/solutions/become-security-compliant'
export const SAVE_COSTS_AND_ESTABLISH_SCALABLE_PROCESSES =
  '/solutions/save-costs-establish-scalable-processes'

//* Company Routes
export const ABOUT_US = '/company/about-us'
export const CAREERS = 'https://wellfound.com/company/zenadminai'
export const PRICING = '/pricing'
export const WHY_ZENADMIN = '/why-zenadmin'
export const BLOG = '/blogs'

// policy link
export const PRIVACY_POLICY = '/privacy-policy'
export const TERMS_OF_SERVICE = '/terms-of-service'
export const PERSONAL_DATA_PROCESSING_AGREEMENT = '/personal-data-processing-agreement'
export const COOKIES_SETTINGS = '/cookies-settings'
//Links
export const HYBRID_APP = 'https://console.zenadmin.ai'
export const DEMO_LINK = 'https://meetings-eu1.hubspot.com/meetings/sharva'

export const CUSTOMERS = '/customers'

export const MARKETING_SWITCH_TO_ZENADMIN = '/marketing/switch-to-zenadmin'
export const ONBOARDING_AND_OFFBOARDING_EMPLOYEE_LIFECYCLE_MANAGEMENT =
  '/onboarding-and-offboarding/employee-lifecycle-management'

export const PARTNERSHIP = '/partnership'

// zenit
export const ZENIT_SAAS_MANAGEMENT = '/zenit/saas-management'
export const ZENIT_MDM = '/zenit/mobile-device-management'
export const ZENIT_IAM = '/zenit/iam'
export const ZENIT_INVENTORY_MANAGEMENT = '/zenit/inventory-management'
export const ZENIT_IDENTITY_MANAGEMENT = '/zenit/identity-management'
export const ZENIT_INTEGRATIONS = '/zenit/integrations'
export const ZENIT_IT_HELPDESK = '/zenit/it-helpdesk'
