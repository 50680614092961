import {motion} from 'motion/react'
import Image from 'next/image'
import clsx from 'clsx'
import classes from './styles.module.css'

function IconsMarquee({
  animate,
  list,
  iconBoxClass,
  customStyles,
  customMarqueeContainer,
  customClass,
}: {
  animate: any
  list: any
  iconBoxClass?: string
  customStyles?: React.CSSProperties
  customMarqueeContainer?: string
  customClass?: string
}) {
  const repeatedIcons = [...list]

  return (
    <div style={{overflow: 'hidden', ...customStyles}} className={customClass}>
      <motion.div
        className={clsx(classes.marqueeContainer, customMarqueeContainer)}
        animate={animate}
        transition={{
          repeat: Infinity,
          duration: 60,
          ease: 'linear',
        }}
      >
        {repeatedIcons.map((icon, index) => (
          <div className={iconBoxClass} key={index}>
            <Image loading="lazy" src={icon} alt={`${icon}-icon`} key={index} />
          </div>
        ))}
      </motion.div>
    </div>
  )
}

export default IconsMarquee
