'use client'
import React, {useEffect, useState} from 'react'
import {hasCookie, setCookie} from 'cookies-next'
import classes from './styles.module.css'
import {PRIVACY_POLICY} from 'utils/getters'
import Image from 'next/image'
import cookieImg from 'assets/icons/common/cookie.svg'

const CookieConsent = () => {
  // Default to false (not showing consent banner)
  const [showConsent, setShowConsent] = useState(false)
  // Add a loading state to prevent flash of consent banner
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const checkCookieConsent = async () => {
      try {
        const hasConsent = await hasCookie('localConsent')
        setShowConsent(hasConsent)
      } catch (error) {
        console.error('Error checking cookie consent:', error)
        // If there's an error, default to not showing the banner
        setShowConsent(false)
      } finally {
        setLoading(false)
      }
    }

    checkCookieConsent()
  }, [])

  const acceptCookie = async () => {
    try {
      await setCookie('localConsent', 'true', {})
      setShowConsent(true)
    } catch (error) {
      console.error('Error setting cookie:', error)
    }
  }

  // Don't render anything while loading or if consent is already given
  if (loading || showConsent) {
    return null
  }

  return (
    <div className={classes.parentContainer}>
      <div className={classes.container}>
        <Image src={cookieImg} alt="cookie" className={classes.cookieImg} />
        <div>
          This website uses cookies to improve user experience. By using our website you consent to
          all cookies in accordance with our <a href={PRIVACY_POLICY}>Cookie Policy</a>.
        </div>
        <button className={classes.button} onClick={acceptCookie}>
          Accept
        </button>
      </div>
    </div>
  )
}

export default CookieConsent
