'use client'
import Image from 'next/image'
import {motion} from 'motion/react'
import {useEffect, useState} from 'react'
import clsx from 'clsx'
import {whyZenAdminData} from 'v1/content/footer'
import classes from './styles.module.css'

export default function WhyZenAdmin() {
  const [activeCardIndex, setActiveCardIndex] = useState(0)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleCardClick = (index: number) => {
    setActiveCardIndex(index)
  }

  const getCardsName = () => {
    return whyZenAdminData?.cards?.map((card, idx) => (
      <span
        key={idx}
        className={clsx(classes.cardName, {[classes.activeCardName]: idx === activeCardIndex})}
        onClick={() => handleCardClick(idx)}
      >
        {card?.heading}
      </span>
    ))
  }

  useEffect(() => {
    if (isMobile) return

    const interval = setInterval(() => {
      setActiveCardIndex(prev => (prev + 1) % whyZenAdminData.cards.length)
    }, 8000)

    return () => clearInterval(interval)
  }, [isMobile])

  return (
    <section className={classes.whyZenadminSection}>
      <div className={classes.headingContainer}>
        <h3 className={classes.title}>{whyZenAdminData?.title}</h3>
        <p className={classes.subTitle}>{whyZenAdminData?.subTitle}</p>
      </div>
      <div className={classes.cardContainer}>
        {whyZenAdminData?.cards?.map((card, idx) => (
          <motion.div
            key={idx}
            className={`${classes.card} ${idx === activeCardIndex ? classes.activeCard : ''}`}
            transition={{duration: 0.5}}
            style={{display: idx === activeCardIndex || !isMobile ? 'block' : 'none'}}
          >
            <span className={classes.cardIconBox}>
              <Image
                loading="lazy"
                src={idx === activeCardIndex ? card?.activeIcon : card?.icon}
                itemType="svg"
                alt="icon"
              />
            </span>
            <h4 className={classes.cardHeading}>{card?.heading}</h4>
            <p className={classes.cardText}>{card?.content}</p>
          </motion.div>
        ))}
      </div>
      <div className={classes.cardNameContainer}>{getCardsName()}</div>
    </section>
  )
}
